<template>
  <v-row class="match-height">
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12">
      <Breadcrumbs :items="items" />
    </v-col>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="50%"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ titleDialog }}
        </v-card-title>
        <v-card-text>
          {{ erroresDialog }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ formTitulo }}</v-card-title>
        <v-tabs
          v-model="tab"
          show-arrows
          class="mb-10"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.id"
            @click="keyComponnet += 1"
          >
            <v-icon
              v-if="tab.error"
              color="error"
            >
              {{ tab.icons }}
            </v-icon>
            <span class="mx-2">{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <!-- tabs item -->
        <v-tabs-items v-model="tab">
          <v-tab-item eager>
            <v-card-text>
              <v-form
                id="form"
                ref="form"
                v-model="isValid"
                class="multi-col-validation mt-1"
                :loading="loadingSaveForm"
                lazy-validation
                :disabled="!deshabilitarForm"
              >
                <v-row>
                  <!-- Imagen -->
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        max-width="300"
                        max-height="300"
                        height="300"
                        width="300"
                        class="mx-auto"
                        :disabled="!deshabilitarForm"
                      >
                        <v-container
                          class="pa-1"
                          style="cursor:pointer;"
                        >
                          <v-img
                            v-if="isUrl(obj.image)"
                            :src="obj.image"
                            height="300"
                            class="text-right"
                            @error="offLink = true"
                          >
                            <template #placeholder>
                              <v-row
                                class="fill-height ma-0 grey"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  v-if="offLink === false"
                                  size="70"
                                  indeterminate
                                  color="grey lighten-5"
                                >
                                </v-progress-circular>
                                <v-icon
                                  v-else
                                  size="50"
                                  dark
                                >
                                  {{ icono_link }}
                                </v-icon>
                              </v-row>
                            </template>
                            <v-expand-transition>
                              <div
                                v-if="hover && isEditing"
                                class="
                              d-flex
                              transition-fast-in-fast-out
                              primary
                              darken-2
                              v-card--reveal
                              white--text
                            "
                                style="height: 100%"
                                @click="onPickFile"
                              >
                                <div>
                                  <div
                                    class="
                                  d-flex
                                  justify-center
                                  white--text
                                  display-1
                                "
                                  >
                                    Click
                                  </div>
                                </div>
                              </div>
                            </v-expand-transition>
                          </v-img>
                          <v-img
                            v-else
                            :src="defaultImage"
                            max-width="300"
                            max-height="300"
                            height="300"
                            width="300"
                            class="text-right"
                            @error="offLink = true"
                          >
                            <template #placeholder>
                              <v-row
                                class="fill-height ma-0 grey"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  v-if="offLink === false"
                                  size="70"
                                  indeterminate
                                  color="grey lighten-5"
                                >
                                </v-progress-circular>
                                <v-icon
                                  v-else
                                  size="50"
                                  dark
                                >
                                  {{ icono_link }}
                                </v-icon>
                              </v-row>
                            </template>
                            <v-expand-transition>
                              <div
                                v-if="hover && isEditing"
                                class="
                              d-flex
                              transition-fast-in-fast-out
                              primary
                              darken-2
                              v-card--reveal
                              white--text
                            "
                                style="height: 100%"
                                @click="onPickFile"
                              >
                                <div>
                                  <div
                                    class="
                                  d-flex
                                  justify-center
                                  white--text
                                  display-1
                                "
                                  >
                                    Click
                                  </div>
                                </div>
                              </div>
                            </v-expand-transition>
                          </v-img>
                          <input
                            id="fileInput"
                            ref="fileInput"
                            type="file"
                            accept="image/*"
                            style="display: none"
                            :disabled="!isEditing"
                            @change="onFilePicked"
                          />
                        </v-container>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-row>
                      <!-- Titulo -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="obj.title"
                          label="Título"
                          outlined
                          dense
                          placeholder="Título"
                          :rules="[
                            (v) => !!v || 'El campo título es obligatorio',
                            (v) => (!!v && v.length <= 40) || 'El campo título admite hasta 40 caracteres',
                          ]"
                          counter="40"
                          maxlength="40"
                          required
                          error-count="2"
                          :error="campos.title.error"
                          :error-messages="errorMsgTitle"
                        >
                          <template #label>
                            <app-label
                              field="Título"
                              required="true"
                            />
                          </template>
                        </v-text-field>
                      </v-col>
                      <!-- Fecha de envio -->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              persistent-hint
                              :prepend-inner-icon="icons.mdiCalendar"
                              placeholder="Fecha de Envío"
                              outlined
                              dense
                              readonly
                              disabled
                              v-bind="attrs"
                              :rules="[
                                (v) => !!v || 'El campo Fecha de Envío es obligatorio',
                              ]"
                              :value="formatDate"
                              v-on="on"
                            >
                              <template #label>
                                <app-label
                                  field="Fecha de Envío"
                                  required="true"
                                />
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="obj.publicationDate"
                            no-title
                            :min="minDate"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- Mensaje Corto -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="obj.shortMessage"
                          label="Mensaje Corto"
                          outlined
                          dense
                          placeholder="Mensaje Corto"
                          :rules="[
                            (v) => !!v || 'El campo Mensaje Corto es obligatorio',
                            (v) => (!!v && v.length <= 178) || 'El campo Mensaje Corto admite hasta 178 caracteres',
                          ]"
                          counter="178"
                          maxlength="178"
                          required
                          error-count="2"
                          :error="campos.shortMessage.error"
                          :error-messages="errorMsgShortMessage"
                        >
                          <template #label>
                            <app-label
                              field="Mensaje Corto"
                              required="true"
                            />
                          </template>
                        </v-text-field>
                      </v-col>
                      <!-- Mensaje Largo -->
                      <v-col cols="12">
                        <v-textarea
                          v-model="obj.largeMessage"
                          outlined
                          dense
                          placeholder="Mensaje Largo"
                          error-count="2"
                          :error="campos.largeMessage.error"
                          :error-messages="errorMsgLargeMessage"
                          :rules="[
                            (v) => !!v || 'El campo Mensaje Largo es obligatorio',
                            (v) => (!!v && v.length <= 250) || 'El campo Mensaje Largo admite hasta 250 caracteres',
                          ]"
                          counter="250"
                          maxlength="250"
                        >
                          <template #label>
                            <app-label
                              field="Mensaje Largo"
                              required="true"
                            />
                          </template>
                        </v-textarea>
                      </v-col>
                      <!-- URL -->
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <v-text-field
                          v-model="obj.link"
                          label="Url"
                          outlined
                          dense
                          placeholder="Url"
                          :rules="[
                            (v) => (v.length === 0 || /^(ftp|http|https|chrome|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|#|-|%)*$/gum.test(v)) || 'El campo Url no es válida',
                          ]"
                          error-count="2"
                          :error="campos.link.error"
                          :error-messages="errorMsgLink"
                        >
                        </v-text-field>
                      </v-col>
                      <!-- Name URL -->
                      <v-col
                        v-if="true"
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="obj.nameLink"
                          label="Nombre de la URL"
                          outlined
                          dense
                          placeholder="Nombre de la URL"
                          counter="100"
                          maxlength="100"
                          error-count="2"
                          :error="campos.nameLink.error"
                          :error-messages="errorMsgNameLink"
                        >
                        </v-text-field>
                      </v-col>
                      <!-- Sector -->
                      <v-col
                        v-if="true"
                        cols="12"
                      >
                        <v-combobox
                          v-model="obj.sector"
                          :items="listSector"
                          item-value="SECT_NOMEN"
                          item-text="SECT_NOMEN"
                          label="Sector"
                          placeholder="Sector"
                          outlined
                          multiple
                          chips
                          small-chips
                          deletable-chips
                          dense
                          required
                          clearable
                          error-count="2"
                          :error="campos.status.error"
                          :error-messages="errorMsgStatus"
                          @change="onChangeSector(obj.sector)"
                          @blur="onBlurSector(obj.sector)"
                        >
                        </v-combobox>
                      </v-col>

                      <!-- Cantidad de Destinatarios Alcanzado -->
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="obj.addressee"
                          label="Cantidad de Destinatarios Alcanzado"
                          outlined
                          dense
                          type="number"
                          readonly
                          placeholder="Cantidad de Destinatarios Alcanzado"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- Estado Civil -->
                  <v-col
                    v-if="false"
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="obj.civilStatus"
                      :items="civilStatus"
                      item-value="id"
                      item-text="name"
                      label="Estado civil"
                      placeholder="Estado civil"
                      outlined
                      dense
                      required
                      clearable
                      error-count="2"
                      :error="campos.status.error"
                      :error-messages="errorMsgStatus"
                    ></v-select>
                  </v-col>
                  <!-- Genero -->
                  <v-col
                    v-if="false"
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="obj.gender"
                      :items="gender"
                      item-value="id"
                      item-text="name"
                      label="Género"
                      placeholder="Género"
                      clearable
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <!-- Rango de Edad -->
                  <v-col
                    v-if="false"
                    cols="12"
                    md="6"
                  >
                    <v-range-slider
                      v-model="obj.ageRange"
                      :max="ageRangeMax"
                      :min="ageRangeMin"
                      hide-details
                      class="align-center"
                      outlined
                    >
                      <template v-slot:prepend>
                        <v-text-field
                          :value="obj.ageRange[0]"
                          label="Edad (Min)"
                          class="mt-0 pt-0"
                          hide-details
                          type="number"
                          outlined
                          dense
                          style="width: 100px"
                          @change="$set(obj.ageRange, 0, $event)"
                        ></v-text-field>
                      </template>
                      <template v-slot:append>
                        <v-text-field
                          :value="obj.ageRange[1]"
                          label="Edad (Max)"
                          class="mt-0 pt-0"
                          hide-details
                          outlined
                          dense
                          type="number"
                          style="width: 100px"
                          @change="$set(obj.ageRange, 1, $event)"
                        ></v-text-field>
                      </template>
                    </v-range-slider>
                  </v-col>
                  <!-- Estado -->
                  <v-col
                    v-if="false"
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="obj.status"
                      :items="listaStatus"
                      item-value="id"
                      item-text="name"
                      label="Estado"
                      placeholder="Estado"
                      clearable
                      outlined
                      dense
                      :rules="[
                        (v) => !!v || 'El campo Estado es obligatorio',
                      ]"
                      required
                      error-count="2"
                      :error="campos.status.error"
                      :error-messages="errorMsgStatus"
                    >
                      <template #label>
                        <app-label
                          field="Estado"
                          required="true"
                        />
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      :disabled="!isValid || !deshabilitarForm"
                      :loading="loadingSaveForm"
                      @click="saveForm"
                    >
                      Enviar
                    </v-btn>
                    <v-btn
                      type="reset"
                      outlined
                      class="mx-2"
                      @click="cancelarForm"
                    >
                      Cancelar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-tab-item>

          <v-tab-item eager>
            <v-row>
              <v-col
                cols="12"
                class="pb-10 px-5"
              >
                <v-card elevation="0">
                  <iframe
                    :src="`https://geoportalcat.guayaquil.gob.ec/portal/apps/View/index.html?appid=1dca6ed833bb4fdd89040547d126813d`"
                    width="100%"
                    height="500"
                    frameborder="0"
                  >
                  </iframe>
                </v-card>
                <!-- iframe -->
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'
import { mdiCalendar, mdiAlertOctagonOutline } from '@mdi/js'
import moment from 'moment'
import axios from 'axios'
import { useRouter } from '@/utils'
import HTTP from '@/utils/axios/axios-config-base'
import AppLabel from '@/components/field/Label'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: { AppLabel, Breadcrumbs },
  computed: {
    formatDate() {
      return this.obj.publicationDate ? moment(this.obj.publicationDate).format('DD-MM-YYYY') : ''
    },
  },
  setup() {
    const { router } = useRouter()
    const formTitulo = ref('Crear alerta')
    const keyComponnet = ref(0)
    const tab = ref(0)
    const tabs = reactive([
      {
        id: '1', title: 'Datos Generales', icons: mdiAlertOctagonOutline, error: false,
      },
      {
        id: '2', title: 'Sectores', icons: mdiAlertOctagonOutline, error: false,
      },
    ])
    const loadingSaveForm = ref(false)
    const title = ref('')
    const image = ref('')
    const imageFile = ref('')
    const defaultImage = ref(require('@/assets/images/default-img.png'))
    const fileInput = ref(null)
    const offLink = ref(false)
    const isEditing = ref(true)
    const shortMessage = ref('')
    const largeMessage = ref('')
    const link = ref('')
    const publicationDate = ref('')
    const location = ref('')
    const status = ref(false)
    const erroresDialog = ref(null)
    const titleDialog = ref(null)
    const dialog = ref(null)
    const listSector = ref([])
    const ageRangeMin = ref(1)
    const ageRangeMax = ref(100)
    const obj = reactive({
      id: null,
      title: '',
      image: null,
      shortMessage: '',
      largeMessage: '',
      link: '',
      nameLink: '',
      publicationDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      location: '',
      status: '',
      civilStatus: '',
      ageRange: [0, 100],
      sector: [{
        SECT_NOMEN: 'TODOS',
        Cuadrante: 'TODOS',
      }],
      alertType: '',
      addressee: 0,
    })

    const listaStatus = ref([
      { id: 'approved', name: 'Aprobado', disabled: false },
      { id: 'pending', name: 'Pendiente', disabled: false },
      { id: 'Denied', name: 'Denegado', disabled: true },
      { id: 'canceled', name: 'Cancelado', disabled: false },
    ])
    const civilStatus = ref([
      { id: 'all', name: 'Todos' },
      { id: 'SOLTERO/A', name: 'Soltero(a)' },
      { id: 'DIVORCIADO/A', name: 'Divorciado(a)' },
      { id: 'CASADO/A', name: 'Casado(a)' },
      { id: 'U.DE HECHO', name: 'Unión Libre' },
      { id: 'VIUDO/A', name: 'Viudo(a)' },
    ])
    const gender = ref([
      { id: 'MASCULINO', name: 'Masculino', disabled: false },
      { id: 'FEMENINO', name: 'Femenino', disabled: false },
      { id: 'all', name: 'Todo', disabled: false },
    ])
    const form = ref(null)
    const isValid = ref(true)
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Alertas',
        disabled: false,
        href: '/alerta',
      },
      {
        text: `${formTitulo.value}`,
        disabled: true,
        href: '/alerta/form',
      },
    ])
    const campos = reactive({
      title: {
        error: false,
        mensajesError: [],
      },
      image: {
        error: false,
        mensajesError: [],
      },
      shortMessage: {
        error: false,
        mensajesError: [],
      },
      largeMessage: {
        error: false,
        mensajesError: [],
      },
      link: {
        error: false,
        mensajesError: [],
      },
      publicationDate: {
        error: false,
        mensajesError: [],
      },
      location: {
        error: false,
        mensajesError: [],
      },
      status: {
        error: false,
        mensajesError: [],
      },
      sector: {
        error: false,
        mensajesError: [],
      },
      civilStatus: {
        error: false,
        mensajesError: [],
      },
      ageRangeMin: {
        error: false,
        mensajesError: [],
      },
      ageRangeMax: {
        error: false,
        mensajesError: [],
      },
      nameLink: {
        error: false,
        mensajesError: [],
      },
    })
    const errorMsgTitle = computed(() => (campos.title.error ? campos.title.mensajesError : []))
    const errorMsgImage = computed(() => (campos.image.error ? campos.image.mensajesError : []))
    const errorMsgShortMessage = computed(() => (campos.shortMessage.error ? campos.shortMessage.mensajesError : []))
    const errorMsgLargeMessage = computed(() => (campos.largeMessage.error ? campos.largeMessage.mensajesError : []))
    const errorMsgLink = computed(() => (campos.link.error ? campos.link.mensajesError : []))
    const errorMsgNameLink = computed(() => (campos.nameLink.error ? campos.nameLink.mensajesError : []))
    const errorMsgPublicationDate = computed(() => (campos.publicationDate.error ? campos.publicationDate.mensajesError : []))
    const errorMsgLocation = computed(() => (campos.location.error ? campos.location.mensajesError : []))
    const errorMsgStatus = computed(() => (campos.status.error ? campos.status.mensajesError : []))
    const errorMsgSector = computed(() => (campos.sector.error ? campos.stasectortus.mensajesError : []))
    const errorMsgCivilStatus = computed(() => (campos.civilStatus.error ? campos.civilStatus.mensajesError : []))
    const errorMsgAgeRangeMin = computed(() => (campos.ageRangeMin.error ? campos.ageRangeMin.mensajesError : []))
    const errorMsgAgeRangeMax = computed(() => (campos.ageRangeMax.error ? campos.ageRangeMax.mensajesError : []))
    const permissions = localStorage.getItem('permissions')
    const deshabilitarForm = computed(() => permissions.includes('Alertas:Alertas:FullAccess') || permissions.includes('Alertas:Alertas:ApproveAccess') || permissions.includes('Alertas:Alertas:CreateAccess') || permissions.includes('Alertas:Alertas:ReadEditAccess'))
    const isUrl = s => {
      const regexp = /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

      return regexp.test(s)
    }

    const onMessage = (title, message) => {
      titleDialog.value = title !== undefined ? title : 'Error'
      erroresDialog.value = message
      dialog.value = true
    }
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })
    const minDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    const getCantidadDestinatarios = async () => {
      let listSectorTemp = []
      let listSectorString = null
      if (obj.sector.length > 0) {
        for (let index = 0; index < obj.sector.length; index += 1) {
          const element = obj.sector[index]
          if (typeof element === 'object') {
            listSectorTemp = [...listSectorTemp, element.SECT_NOMEN]
          }
          if (typeof element === 'string') {
            listSectorTemp = [...listSectorTemp, element]
          }
        }
      }

      if (listSectorTemp.includes('TODOS')) {
        listSectorString = null
      } else {
        listSectorString = listSectorTemp.length > 0 ? listSectorTemp.toString() : null
      }
      const objToSave = {
        notificationType: 'notifications',
        category: null,
        gender: null,
        locations: listSectorString,
        maritalStatus: null,
        ageInitial: null,
        ageFinal: null,
      }
      try {
        const URL = `${HTTP.defaults.baseURL}identity-api/users-data/count-mobiles`
        const resp = await HTTP.post(URL, objToSave)
        obj.addressee = 0
        obj.addressee = resp.data.devices
      } catch (error) {
        console.log(error)
      }
    }

    const onChangeSector = async idsSector => {
      for (let index = 0; index < idsSector.length; index += 1) {
        if (idsSector[index].SECT_NOMEN === 'TODOS') {
          obj.sector = []
          obj.sector = [...obj.sector, {
            SECT_NOMEN: 'TODOS',
            Cuadrante: 'TODOS',
          }]
        }
      }
      await getCantidadDestinatarios()
    }

    const onBlurSector = async idsSector => {
      if (idsSector.length === 0) {
        obj.sector = []
        obj.sector = [...obj.sector, {
          SECT_NOMEN: 'TODOS',
          Cuadrante: 'TODOS',
        }]
      }
      await getCantidadDestinatarios()
    }

    const getSector = async () => {
      const URL = 'https://geoportalcat.guayaquil.gob.ec/arcgis/rest/services/Geoportal_Actualizado/GEOPORTAL_ACTUALIZADO/MapServer/4/query?where=1=1&outFields=SECT_NOMEN,cuadrante&returnGeometry=false&f=pjson'
      const resp = await axios.get(URL)
      if (resp.status === 200) {
        listSector.value = []
        for (let index = 0; index < resp.data.features.length; index += 1) {
          listSector.value.push({
            ...resp.data.features[index].attributes,
          })
        }
        listSector.value = [...listSector.value, {
          SECT_NOMEN: 'TODOS',
          Cuadrante: 'TODOS',
        }]
      }
    }
    const getCurrentUser = async () => {
      let value = null
      const URL = `${HTTP.defaults.baseURL}identity-api/user/me`
      try {
        const resp = await HTTP.get(URL)
        value = resp.data.areaId
      } catch (error) {
        console.log(error)
      }

      return value
    }
    getSector()
    getCantidadDestinatarios()

    return {
      menu: ref(false),
      tab,
      tabs,
      keyComponnet,
      formTitulo,
      loadingSaveForm,
      title,
      image,
      imageFile,
      defaultImage,
      fileInput,
      offLink,
      isEditing,
      shortMessage,
      largeMessage,
      link,
      publicationDate,
      location,
      status,
      obj,
      listaStatus,
      form,
      isValid,
      items,
      campos,
      errorMsgTitle,
      errorMsgImage,
      errorMsgShortMessage,
      errorMsgLargeMessage,
      errorMsgLink,
      errorMsgPublicationDate,
      errorMsgLocation,
      errorMsgStatus,
      errorMsgSector,
      errorMsgCivilStatus,
      errorMsgAgeRangeMin,
      errorMsgAgeRangeMax,
      errorMsgNameLink,
      erroresDialog,
      titleDialog,
      dialog,
      notificaciones,
      minDate,
      listSector,
      civilStatus,
      ageRangeMin,
      ageRangeMax,
      gender,
      isUrl,
      onMessage,
      getCantidadDestinatarios,
      onChangeSector,
      onBlurSector,
      deshabilitarForm,
      getCurrentUser,

      // iconos
      icons: {
        mdiCalendar,
        mdiAlertOctagonOutline,
      },
      previewFiles: event => {
        if (event) {
          const reader = new FileReader()
          reader.readAsDataURL(event)
          reader.onload = () => {
            image.value = reader.result
            obj.image = reader.result
          }
        } else {
          image.value = ''
          obj.image = ''
        }

        // var reader = new FileReader()
        // reader.readAsDataURL(event.target.files[0])
        // reader.onload = () => {
        //   image.value = reader.result
        //   obj.value.image = reader.result
        // };
      },
      saveForm: async () => {
        if (form.value.validate()) {
          loadingSaveForm.value = true

          let listSectorTemp = []
          let listSectorString = null
          if (obj.sector.length > 0) {
            for (let index = 0; index < obj.sector.length; index += 1) {
              const element = obj.sector[index]
              if (typeof element === 'object') {
                listSectorTemp = [...listSectorTemp, element.SECT_NOMEN]
              }
              if (typeof element === 'string') {
                listSectorTemp = [...listSectorTemp, element]
              }
            }
          }

          if (listSectorTemp.includes('TODOS')) {
            listSectorString = null
          } else {
            listSectorString = listSectorTemp.length > 0 ? listSectorTemp.toString() : null
          }

          const areaTemp = await getCurrentUser()
          const objToSave = {
            title: obj.title,

            // image: obj.image,
            shortMessage: obj.shortMessage,
            largeMessage: obj.largeMessage,
            link: obj.link,
            nameLink: obj.nameLink,
            publicationDate: obj.publicationDate,
            location: obj.location,
            status: 'pending',
            gender: obj.gender,
            civilStatus: obj.civilStatus,
            sector: listSectorString,
            ageMin: null,
            ageMax: null,
            alertType: 'alert',
            addressee: obj.addressee,
            areaId: areaTemp || null,
          }

          // objeto.status = status.value
          try {
            const URL = `${HTTP.defaults.baseURL}marketing-api/notifications`
            const resp = await HTTP.post(URL, objToSave)
            loadingSaveForm.value = false
            if (resp.status === 201) {
              if (typeof obj.image === 'object') {
                loadingSaveForm.value = true
                try {
                  const URLImg = `${HTTP.defaults.baseURL}marketing-api/notifications/${resp.data.id}/images`
                  const formData = new FormData()
                  formData.append('file', obj.image)
                  const imgResp = await HTTP.post(URLImg, formData)
                } catch (error) {}
              }
              notificaciones.snackbar = true
              notificaciones.color = 'success'
              notificaciones.text = 'El registro se a creado satisfactoriamente'
              loadingSaveForm.value = false
              router.push({
                name: 'alerta',
                params: {
                  notificaciones: {
                    snackbar: notificaciones.snackbar,
                    text: notificaciones.text,
                    color: notificaciones.color,
                  },
                },
              })
            }
          } catch (error) {
            const { errors, title } = error.response.data
            if (errors) {
              let errorDialog = true
              if (errors.Title) {
                for (let index = 0; index < errors.Title.length; index += 1) {
                  campos.title.mensajesError.push(errors.Title[index])
                }
                errorDialog = false
                campos.title.error = true
              }
              if (errors.Image) {
                for (let index = 0; index < errors.Image.length; index += 1) {
                  campos.image.mensajesError.push(errors.Image[index])
                }
                errorDialog = false
                campos.image.error = true
              }
              if (errors.ShortMessage) {
                for (let index = 0; index < errors.ShortMessage.length; index += 1) {
                  campos.shortMessage.mensajesError.push(errors.ShortMessage[index])
                }
                errorDialog = false
                campos.shortMessage.error = true
              }
              if (errors.LargeMessage) {
                for (let index = 0; index < errors.LargeMessage.length; index += 1) {
                  campos.largeMessage.mensajesError.push(errors.LargeMessage[index])
                }
                errorDialog = false
                campos.largeMessage.error = true
              }
              if (errors.Link) {
                for (let index = 0; index < errors.Link.length; index += 1) {
                  campos.link.mensajesError.push(errors.Link[index])
                }
                errorDialog = false
                campos.link.error = true
              }
              if (errors.nameLink) {
                for (let index = 0; index < errors.nameLink.length; index += 1) {
                  campos.nameLink.mensajesError.push(errors.nameLink[index])
                }
                errorDialog = false
                campos.nameLink.error = true
              }
              if (errors.PublicationDate) {
                for (let index = 0; index < errors.PublicationDate.length; index += 1) {
                  campos.publicationDate.mensajesError.push(errors.PublicationDate[index])
                }
                errorDialog = false
                campos.publicationDate.error = true
              }
              if (errors.Location) {
                for (let index = 0; index < errors.Location.length; index += 1) {
                  campos.location.mensajesError.push(errors.Location[index])
                }
                errorDialog = false
                campos.location.error = true
              }
              if (errors.civilStatus) {
                for (let index = 0; index < errors.civilStatus.length; index += 1) {
                  campos.civilStatus.mensajesError.push(errors.civilStatus[index])
                }
                errorDialog = false
                campos.civilStatus.error = true
              }
              if (errors.sector) {
                for (let index = 0; index < errors.sector.length; index += 1) {
                  campos.sector.mensajesError.push(errors.sector[index])
                }
                errorDialog = false
                campos.sector.error = true
              }
              if (errors.ageMin) {
                for (let index = 0; index < errors.ageMin.length; index += 1) {
                  campos.ageMin.mensajesError.push(errors.ageMin[index])
                }
                errorDialog = false
                campos.ageMin.error = true
              }
              if (errors.ageMax) {
                for (let index = 0; index < errors.ageMax.length; index += 1) {
                  campos.ageMax.mensajesError.push(errors.ageMax[index])
                }
                errorDialog = false
                campos.ageMax.error = true
              }

              // if (errors.Status) {
              //   for (let index = 0; index < errors.Status.length; index += 1) {
              //     campos.status.mensajesError.push(errors.Status[index])
              //   }
              //   errorDialog = false
              //   campos.status.error = true
              // }
              if (errorDialog) {
                let error = ''
                const keysError = Object.keys(errors)
                for (let index = 0; index < keysError.length; index += 1) {
                  for (let index1 = 0; index1 < errors[keysError[index]].length; index1 += 1) {
                    error += errors[keysError[index]][index1]
                  }
                }
                notificaciones.snackbar = true
                notificaciones.color = 'error'
                notificaciones.text = error
              }
            }
            loadingSaveForm.value = false
          }
        }
      },
      onPickFile: () => {
        fileInput.value.click()
      },
      onFilePicked: event => {
        const { files } = event.target
        const filename = files[0] ? files[0].name : ''
        const size = files[0] ? files[0].size : ''
        const ext = files[0] ? files[0].type : ''
        if (files[0]) {
          // si no tiene una extension muestro un error
          if (filename && filename.lastIndexOf('.') <= 0) {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = 'Por favor selecciona un archivo valido'
          } else if (size && size >= 1000000) {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = 'Máximo 1 fichero. Límite de 1 MB'
          } else if (ext && ext !== 'image/jpeg' && ext !== 'image/jpg' && ext !== 'image/png') {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = `Los archivos de tipo ${ext} no estan permitidos. Tipos permitidos: png jpg jpeg.`
          } else {
            const fileReader = new FileReader()
            if (fileReader) {
              fileReader.addEventListener('load', () => {
                defaultImage.value = fileReader.result
              })
              fileReader.readAsDataURL(files[0])
              obj.image = files[0]
            }
          }
        }
      },
      cancelarForm: () => {
        router.push({ name: 'alerta' })
      },
    }
  },
}
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
</style>
